import React, { useState } from "react";

function CFGroupPlans() {
  const [formData, setFormData] = useState({
    planName: "",
    planType: "",
    planNumber: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic here to handle form submission
  };

  return (
    <div>
      <h1>CF Group Plans</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Plan Name:
          <input
            type="text"
            name="planName"
            value={formData.planName}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Plan Type:
          <input
            type="text"
            name="planType"
            value={formData.planType}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Plan Number:
          <input
            type="text"
            name="planNumber"
            value={formData.planNumber}
            onChange={handleChange}
          />
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default CFGroupPlans;
