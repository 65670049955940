import React, { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/auth";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
} from "devextreme-react/data-grid";
import "devextreme-react/text-area";
import "devextreme/data/data_source";
import "./debtSummaryStyles.scss";
import "./debtsummary.css";
import { CFTaxResultsExcel } from "./cfTaxResultsExcel";
import { TaxSummaryData } from "./cfData";

const renderDescriptionCell = (data) => {
  const { data: rowData } = data;
  let style = {};
  if (rowData.LINETYPE === "H") {
    style = {
      color: "white",
      backgroundColor: "black",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else if (rowData.LINETYPE === "X") {
    style = {
      color: "blue",
      backgroundColor: "lightgrey",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else if (rowData.LINETYPE === "T") {
    style = {
      color: "black",
      backgroundColor: "lightgrey",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else {
    style = {
      backgroundColor: "",
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
    };
  }

  return <div style={style}>{rowData.DESCRIPTION}</div>;
};

const renderValueFieldCellCombined = (data, column) => {
  const { data: rowData } = data;

  let style = {};
  if (rowData.LINETYPE === "B") {
    style = {};
    return <div style={style}>&nbsp;</div>;
  }

  let displayValue = "";
  let printColumn = "";
  let valueField = "";

  switch (column) {
    case 1:
      printColumn = rowData.PRINTCOLUMN1;
      valueField = rowData.VALUEFIELD;
      break;
    case 2:
      printColumn = rowData.PRINTCOLUMN2;
      valueField = rowData.VALUEFIELD2;
      break;
    case 3:
      printColumn = rowData.PRINTCOLUMN3;
      valueField = rowData.VALUEFIELD3;
      break;
    default:
  }

  if (printColumn === "N") {
    style = {};
    return <div style={style}>&nbsp;</div>;
  }

  if (rowData.LINETYPE === "T" || rowData.LINETYPE === "X") {
    style = {
      color: "black",
      backgroundColor: "#D9D9D9",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderRight: "1px solid black",
    };
  } else if (rowData.LINETYPE === "H") {
    style = {
      color: "black",
      backgroundColor: "#E6D180",
      borderRight: "1px solid black",
    };
  } else {
    style = {
      backgroundColor: "",
      borderRight: "1px solid black",
    };
  }

  if (printColumn === "N") {
    style = {};
  }

  if (rowData.LINETYPE === "H") {
    switch (column) {
      case 1:
        displayValue = rowData.HEADINGONE;
        break;
      case 2:
        displayValue = rowData.HEADINGTWO;
        break;
      case 3:
        displayValue = rowData.TOTALHEADING;
        break;
      default:
    }
  } else {
    const isNegative = valueField < 0;
    const absoluteValue = Math.abs(valueField);

    if (absoluteValue === 0) {
      displayValue = ""; // Display blank field for zero value
    } else {
      const formattedValue = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(absoluteValue);

      displayValue = isNegative ? `(${formattedValue})` : formattedValue;
    }
  }

  if (
    (rowData.LINETYPE === "H" ||
      rowData.LINETYPE === "X" ||
      rowData.LINETYPE === "B") &&
    printColumn !== "Y" &&
    printColumn !== "H"
  ) {
    return <div style={style}>&nbsp;</div>;
  } else if (printColumn === "H") {
    if (valueField === "") {
      return <div style={style}>&nbsp;</div>;
    } else {
      return <div style={{ ...style, textAlign: "center" }}>{valueField}</div>;
    }
  } else {
    return <div style={style}>{displayValue}&nbsp;&nbsp;</div>;
  }
};

const renderValueFieldCell = (data) => renderValueFieldCellCombined(data, 1);
const renderValueFieldCell2 = (data) => renderValueFieldCellCombined(data, 2);
const renderValueFieldCell3 = (data) => renderValueFieldCellCombined(data, 3);

const CFTaxResultx = (props) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hideSections, setHideSections] = useState(false);
  const [EditExcelOn, setEditExcelOn] = useState(false);
  const [clientCode, setClientCode] = useState(props.clientCode);
  const [ShowCreateClientPresentation, setShowCreateClientPresentation] =
    useState(props.ShowCreateClientPresentation);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await TaxSummaryData(props.clientCode);
        console.log("Fetched Data:", result.data);
        setData(result.data);
        setFilteredData(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [props.clientCode]);

  useEffect(() => {
    if (hideSections) {
      setFilteredData(data.filter((row) => row.DONOTPRINT !== 1));
    } else {
      setFilteredData(data);
    }
  }, [hideSections, data]);

  const handleSelectionChanged = (e) => {
    console.log("Selected Row:", e.selectedRowKeys);
  };

  const handleEditingStart = (e) => {
    const rowToBeEdited = e.data;
    if (rowToBeEdited.someField === "someValue") {
      e.cancel = true;
    }
  };

  const handleCheckboxChange = (e) => {
    setHideSections(e.target.checked);
  };

  const CreateExcel = () => {
    console.log("Edit Batch Clicked");
    setEditExcelOn(true);
  };

  const handleMappingUpdated2 = (value) => {
    setEditExcelOn(value);
  };

  return (
    <>
      {!isLoading && (
        <>
          <div className="root">
            <h1>Tax Analysis - Year 20xx</h1>
          </div>
          <label style={{ marginLeft: "15px" }}>
            <input
              type="checkbox"
              checked={hideSections}
              onChange={handleCheckboxChange}
            />
            Hide Sections
          </label>
          {ShowCreateClientPresentation !== true && (
            <Button
              text="Create Excel"
              onClick={CreateExcel}
              style={{
                width: "200px",
                height: "30px",
                marginTop: "2px",
                marginBottom: "10px",
                marginLeft: "15px",
              }}
            />
          )}
          <div className="content-block2 dx-card responsive-paddings">
            <div className="custom-container3" style={{ height: "850px" }}>
              <DataGrid
                dataSource={filteredData}
                onRowPrepared={() => {}}
                onCellPrepared={(e) => {
                  e.cellElement.style.padding = "0px";
                }}
                scrolling={{ mode: "virtual" }}
                showBorders={false}
                showRowLines={false}
                remoteOperations={false}
                onSelectionChanged={handleSelectionChanged}
                onEditingStart={handleEditingStart}
                width={"65%"}
                height={"100%"}
              >
                <FilterRow visible={false} applyFilter={"auto"} />
                <HeaderFilter visible={false} />
                <Paging enabled={false} />
                <Column
                  dataField="UNIQUEID"
                  caption="Unique ID"
                  visible={false}
                />
                <Column dataField="ROWNUMBER" caption="Row" visible={false} />
                <Column
                  dataField="DESCRIPTION"
                  caption={`Tax Analysis for ${props.clientCode}`}
                  width={250}
                  visible={true}
                  cellRender={renderDescriptionCell}
                />
                <Column
                  dataField="VALUEFIELD"
                  caption=""
                  alignment="right"
                  cellRender={renderValueFieldCell}
                  width={100}
                />
                <Column
                  dataField="VALUEFIELD2"
                  caption=""
                  alignment="right"
                  cellRender={renderValueFieldCell2}
                  width={100}
                />
                <Column
                  dataField="VALUEFIELD3"
                  caption=""
                  alignment="right"
                  cellRender={renderValueFieldCell3}
                  width={100}
                />
                <Column
                  dataField="FORMULAFIELD"
                  caption="Formula"
                  visible={false}
                />
                <Column dataField="DONOTPRINT" caption="skip" visible={false} />
                <Column dataField="LINETYPE" caption="Type" visible={false} />
                <Column
                  dataField="HEADINGONE"
                  caption="head 1"
                  visible={false}
                />
                <Column
                  dataField="HEADINGTWO"
                  caption="head 2"
                  visible={false}
                />
                <Column
                  dataField="TOTALHEADING"
                  caption="total head"
                  visible={false}
                />
              </DataGrid>
            </div>
          </div>
        </>
      )}
      {isLoading && (
        <div className="spinner-container">
          <p>Loading data, please wait...</p>
        </div>
      )}
      {EditExcelOn === true && (
        <div>
          <CFTaxResultsExcel
            clientCode={clientCode}
            onMappingUpdated2={handleMappingUpdated2}
          />
        </div>
      )}
    </>
  );
};

export default function CFTaxResult(props) {
  const { user } = useAuth();
  return (
    <CFTaxResultx
      clientCode={user.thisClientcode}
      thisWidth={props.thisWidth}
      showPrior={props.showPrior}
      ShowCreateClientPresentation={props.ShowCreateClientPresentation}
    />
  );
}
