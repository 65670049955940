import React, { useEffect } from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { TaxSummaryData } from "./cfData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "./debtSummaryStyles.scss";
import "./debtsummary.css";

export const CFTaxResultsExcel = ({ clientCode, onMappingUpdated2 }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    const processComplete = async () => {
      setIsLoading(true);
      await createExcel();
      setIsLoading(false);
      onMappingUpdated2(false); // Reset the state properly after creation
    };

    processComplete();
  }, [clientCode, onMappingUpdated2]);

  const applyDescriptionCellStyle = (cell, item, heading) => {
    // Set font color for all cases
    cell.font = { color: { argb: "FF000000" } }; // Black color

    // Define common border style for simplicity
    const commonBorderStyle = { style: "thin", color: { argb: "FF000000" } }; // Black

    // Apply background color and borders based on LINETYPE
    if (["T", "X"].includes(item.LINETYPE)) {
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD3D3D3" }, // Light grey background
      };
      cell.border = {
        top: commonBorderStyle,
        bottom: commonBorderStyle,
        right: commonBorderStyle,
        left: commonBorderStyle,
      };
    } else if (item.LINETYPE === "") {
      // No specific background color, but black font color is already set
      cell.border = {
        right: commonBorderStyle,
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
    } else if (item.LINETYPE === "H") {
      cell.border = {
        right: commonBorderStyle,
        top: commonBorderStyle,
        bottom: commonBorderStyle,
        left: commonBorderStyle,
      };
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFE6D180" }, // Custom yellow background
      };
      //cell.value = ""; // This ensures the cell displays no content, including zero
      return;
      // Borders are commented out in your example, so not adding them
    } else if (item.LINETYPE === "B") {
      cell.value = ""; // This ensures the cell displays no content, including zero
      return;
    }

    //const valueCell = rowData.VALUEFIELD;
    cell.numFmt = "$#,##0;($#,##0)"; // This is Excel's format for currency
  };

  const applyValueFieldCellStyle = (cell, item, column) => {
    //console.log("cell value:", cell.value);
    // Define common border style
    const commonBorderStyle = { style: "thin", color: { argb: "FF000000" } }; // Black

    // Apply styles based on LINETYPE
    if (item.LINETYPE === "H") {
      cell.font = { color: { argb: "FFFFFFFF" } }; // White color
      cell.fill = {
        bold: true,
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF000000" }, // Black background
      };
      cell.border = {
        top: commonBorderStyle,
        bottom: commonBorderStyle,
        right: commonBorderStyle,
        left: commonBorderStyle,
      };
    } else if (item.LINETYPE === "X" || item.LINETYPE === "T") {
      cell.font = {
        bold: true,
        color: { argb: item.LINETYPE === "X" ? "FF000000" : "FF000000" },
      }; // Blue for "X", Black for "T"
      cell.fill = {
        bold: true,
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD9D9D9" }, // Grey background
      };
      cell.border = {
        top: commonBorderStyle,
        bottom: commonBorderStyle,
        right: commonBorderStyle,
        left: commonBorderStyle,
      };
    } else if (item.LINETYPE === "") {
      cell.border = {
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      };
    } else {
      // For other LINETYPE, no specific style applied here
      // Assuming default or custom logic might be added
    }
    if (item.LINETYPE === "H") {
      //console.log("column:", column, item.HEADINGONE);
      if (column === 1) {
        cell.value = item.HEADINGONE; //cell.HEADINGONE; // This ensures the cell displays no content, including zero
        return;
      }
      if (column === 2) {
        cell.value = item.HEADINGTWO; //cell.HEADINGONE; // This ensures the cell displays no content, including zero
        return;
      }
      if (column === 3) {
        cell.value = item.TOTALHEADING; //cell.HEADINGONE; // This ensures the cell displays no content, including zero
        return;
      }
    }
    if (item.LINETYPE === "B") {
      cell.value = ""; // This ensures the cell displays no content, including zero
      return;
    }

    cell.numFmt = "$#,##0;($#,##0)";
    // Set the value of the cell to DESCRIPTION
    //    cell.value = item.DESCRIPTION;
  };

  const createExcel = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Tax");

      worksheet.columns = [
        { header: "", width: 10 },
        { header: `Cash Flow ${clientCode}`, key: "DESCRIPTION", width: 50 },
        { header: "", key: "VALUEFIELD", width: 25 },
        { header: "", key: "VALUEFIELD2", width: 20 },
        { header: "", key: "VALUEFIELD3", width: 20 },
      ];

      const dataResponse = await TaxSummaryData(clientCode);
      const data = dataResponse.data;

      if (Array.isArray(data)) {
        data.forEach((item) => {
          const row = worksheet.addRow({
            ROWNUMBER: item.ROWNUMBER,
            DESCRIPTION: item.DESCRIPTION,
            VALUEFIELD: item.VALUEFIELD,
            VALUEFIELD2: item.VALUEFIELD2,
            VALUEFIELD3: item.VALUEFIELD3,
            FORMULAFIELD: item.FORMULAFIELD,
            PRINTCOLUMN1: item.PRINTCOLUMN1,
            PRINTCOLUMN2: item.PRINTCOLUMN2,
            PRINTCOLUMN3: item.PRINTCOLUMN3,
            HEADINGONE: item.HEADINGONE,
            HEADINGTWO: item.HEADINGTWO,
            TOTALHEADING: item.TOTALHEADING,
            UNIQUEID: item.UNIQUEID,
          });

          applyDescriptionCellStyle(row.getCell("DESCRIPTION"), item);
          applyValueFieldCellStyle(row.getCell("VALUEFIELD"), item, 1);
          applyValueFieldCellStyle(row.getCell("VALUEFIELD2"), item, 2);
          applyValueFieldCellStyle(row.getCell("VALUEFIELD3"), item, 3);
        });

        const buf = await workbook.xlsx.writeBuffer();
        //console.log("Buffer created successfully");

        const blob = new Blob([buf], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        //console.log("Blob created successfully");

        // Ensure saveAs is working correctly
        try {
          saveAs(blob, `${clientCode}TaxInfo.xlsx`);
          //console.log("saveAs called successfully");

          MySwal.fire({
            icon: "success",
            title: "Excel File Created",
            text: `Your file ${clientCode}TaxInfo.xlsx has been successfully created.`,
            customClass: { container: "high-z-index" },
          });
        } catch (saveError) {
          console.error("An error occurred while saving the file:", saveError);
          MySwal.fire({
            icon: "error",
            title: "Error",
            text: "There was an issue saving the Excel file.",
            customClass: { container: "high-z-index" },
          });
        }
      } else {
        console.error("Expected data to be an array but got:", typeof data);
      }
    } catch (error) {
      console.error(
        "An error occurred during the Excel file creation process:",
        error
      );
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue creating the Excel file.",
        customClass: { container: "high-z-index" },
      });
    }
  };

  return (
    <>
      {isLoading && (
        <div className="spinner-container">
          <p>Building the Excel File, please wait &nbsp;&nbsp;</p>
          <FontAwesomeIcon icon={faSpinner} spin className="large-spinner" />
        </div>
      )}
    </>
  );
};
