import CustomStore from "devextreme/data/custom_store";
function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

export const getBanks = (myClient) => {
  //var myClient = 1;
  const activeOnly = true;
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
      activeOnly: activeOnly,
    }),
  };
  //console.log("client sent", myClient);
  const url = `${process.env.REACT_APP_BASE_URL}/getClientbankAccounts`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      //console.log("client " + myClient);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      //console.log("asset groups", json);
      return {
        data: json.user_response.bankq,
      };
    });
};

export const getBankName = (myClient, MybankAccount) => {
  //var myClient = 1;
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
      sentBankAccount: MybankAccount,
    }),
  };

  //console.log("client sent", myClient, "bank account sent", MybankAccount);

  const url = `${process.env.REACT_APP_BASE_URL}/getClientbankAccountName`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      //console.log("client " + myClient);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      console.log("date returned: ", json.user_response.returneddate);
      //   "client sent",
      //   myClient,
      //   "bank account",
      //   MybankAccount,
      //   "bank name ",
      //   json
      // );
      return {
        data: json.user_response.returnedName,
        date: json.user_response.returneddate,
        daterow: json.user_response.daterow,
        descriptionrow: json.user_response.descriptionrow,
        paymentsrow: json.user_response.paymentsrow,
        depositsrow: json.user_response.depositsrow,
      };
    });
};

export const mystore = (myClient, activeOnly) =>
  new CustomStore({
    key: "UNIQUEID",
    load: (loadOptions) => {
      let params = "?";
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });
      //console.log("here");
      params = params.slice(0, -1);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          sentclientcode: myClient,
          Parameters: params,
          activeOnly: activeOnly,
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/getClientbankAccounts`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          ////console.log("client " + myClient);
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          console.log(
            "from client: ",
            myClient,
            "data: ",
            json.user_response.bankq
          );
          return {
            data: json.user_response.bankq,
            totalCount: json.user_response.totalCount,
            key: json.user_response.keyname,
          };
        });
    },
    insert: (values) => {
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          ThisFunction: "insert",
          keyvaluepair: values,
          SentCompany: myClient,
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/updateClientbankAccounts`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
    remove: (key) => {
      //console.log(key);
      ////console.log(values);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          SentCompany: key,
          ThisFunction: "delete",
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/updateClientbankAccounts`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
    update: (key, values) => {
      //console.log("key: ", key);
      //console.log("values: ", values);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          ThisFunction: "change",
          SentCompany: key,
          keyvaluepair: values,
        }),
      };
      //console.log("key: ', key", "values", values);
      const url = `${process.env.REACT_APP_BASE_URL}/updateClientbankAccounts`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
  });

export const mystore2 = (myClient) => {};

export const myStore3 = () => {
  var myClient = 1;
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getBanks`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      ////console.log("client " + myClient);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      //console.log("banks list", json);
      return {
        data: json.user_response.loginq,
      };
    });
};

export const myStore4 = (myClient) => {
  //var myClient = 1;
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getClientOwners`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      ////console.log("client " + myClient);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      //console.log("banks list", json);
      return {
        data: json.user_response.bankq,
      };
    });
};

export const myStore5 = (myClient) => {
  //var myClient = 1;
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: myClient,
    }),
  };
  const url = `${process.env.REACT_APP_BASE_URL}/getBankAccountTypes`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      ////console.log("client " + myClient);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      //console.log("banks list", json);
      return {
        data: json.user_response.loginq,
      };
    });
};
///////////////////////////////////////
/////
/////            trying to figure this out
/////
/////////////////////////////////////////

export const updateImportFilexx = (clientcode, bankaccount, dataArray) => {
  //console.log("array from web page", dataArray.data);
  //var myClient = 1;
  // const sentArray = dataArray.data.map(
  //   ([date, description, payments, deposits, total]) => ({
  //     DATEFIELD: date,
  //     DESCRIPTIONFIELD: description,
  //     PAYMENTSFIELD: payments,
  //     DEPOSITSFIELD: deposits,
  //     TOTALFIELD: total,
  //   })
  // );

  const sentArray = dataArray.data.map(
    ([fld1, fld2, fld3, fld4, fld5, fld6, fld7, fld8, fld9, fld10]) => ({
      fld1: fld1,
      fld2: fld2,
      fld3: fld3,
      fld4: fld4,
      fld5: fld5,
      fld6: fld6,
      fld7: fld7,
      fld8: fld8,
      fld9: fld9,
      fld11: fld10,
    })
  );

  // Create the final object
  const dataToSend = {
    sentArray: sentArray,
  };
  //  console.log("data to send", dataToSend);
  //  console.log("sentarray", sentArray);
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: clientcode,
      sentBankAccount: bankaccount,
      sentArray: sentArray,
    }),
  };
  // console.log(
  //   "client sent",
  //   clientcode,
  //   "bank account sent",
  //   bankaccount,
  //   "data sent",
  //   dataToSend
  // );
  const url = `${process.env.REACT_APP_BASE_URL}/SendImportFileData2`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      ////console.log("client " + myClient);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      //console.log("banks list", json);
      return {
        data: json.user_response.loginq,
      };
    });
};
/////////////////////////////////
/////  this one works
////////////////////////////////

export const updateImportFileV2 = (
  clientcode,
  bankaccount,
  dataArray,
  processmap
) => {
  // console.log(
  //   "clientcode",
  //   clientcode,
  //   "banksaccount",
  //   bankaccount,
  //   "array from web page",
  //   dataArray
  // );
  //var myClient = 1;

  const sentArray = dataArray.map(
    ([date, description, payments, deposits, total, segmentfield]) => ({
      DATEFIELD: date,
      DESCRIPTIONFIELD: description,
      PAYMENTSFIELD: payments,
      DEPOSITSFIELD: deposits,
      TOTALFIELD: total,
      SEGMENTFIELD: segmentfield,
    })
  );

  // Create the final object
  const dataToSend = {
    sentArray: sentArray,
  };

  console.log("sent array - this is sent to clarion", sentArray);
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: clientcode,
      sentBankAccount: bankaccount,
      sentArray: sentArray,
      processmap: processmap,
    }),
  };
  console.log(
    "client sent",
    clientcode,
    "bank account sent",
    bankaccount,
    "data sent (sent Array",
    sentArray
  );
  const url = `${process.env.REACT_APP_BASE_URL}/SendImportFileData`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      ////console.log("client " + myClient);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      //console.log("banks list", json);
      return {
        count: json.user_response.count,
        errorcount: json.user_response.errorcount,
        data: json.user_response.loginq,
      };
    });
};
////////////////////////////////
export const updateImportFile = (clientcode, bankaccount, dataArray) => {
  console.log("array from web page", dataArray.data);
  const sentArray = dataArray.data.map(
    ([
      date,
      description,
      segment,
      payments,
      deposits,
      total,
      transactioncode,
    ]) => ({
      DATEFIELD: date,
      DESCRIPTIONFIELD: description,
      SEGMENTFIELD: segment,
      PAYMENTSFIELD: payments,
      DEPOSITSFIELD: deposits,
      TOTALFIELD: total,
      TRANSACTIONCODEFIELD: transactioncode,
    })
  );

  // Create the final object
  const dataToSend = {
    sentArray: sentArray,
  };

  //console.log("sent array - this is sent to clarion", sentArray);
  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: clientcode,
      sentBankAccount: bankaccount,
      sentArray: sentArray,
    }),
  };
  console.log(
    "client sent",
    clientcode,
    "bank account sent",
    bankaccount,
    "data sent",
    dataToSend
  );
  const url = `${process.env.REACT_APP_BASE_URL}/SendImportFileData3`;
  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      ////console.log("client " + myClient);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      //console.log("banks list", json);
      return {
        data: json.user_response.loginq,
        count: json.user_response.count,
        errorcount: json.user_response.errorcount,
      };
    });
};

export const updateExcelTransactions = (clientCode, bankAccount, dataArray) => {
  console.log("array from web page", dataArray);
  let formattedDate = null;
  const sentArray = dataArray
    .map((transaction) => {
      if (
        transaction.LONGDATEFIELD !== "" &&
        transaction.LONGDATEFIELD !== null &&
        transaction.LONGDATEFIELD !== undefined
      ) {
        const newdate = new Date(transaction.LONGDATEFIELD);
        console.log("new date is", newdate);

        const utcDate = new Date(
          Date.UTC(
            newdate.getUTCFullYear(),
            newdate.getUTCMonth(),
            newdate.getUTCDate()
          )
        );
        formattedDate = utcDate.toISOString().substring(0, 10);
        console.log("formatted date is", formattedDate);
      } else {
        formattedDate = "";
      }

      return {
        LONGDATEFIELD: formattedDate,
        DATEFIELD: transaction.DATEFIELD,
        DESCRIPTIONFIELD: transaction.DESCRIPTIONFIELD,
        PAYMENTSFIELD: transaction.DEPOSITSFIELD,
        DEPOSITSFIELD: transaction.PAYMENTSFIELD,
        SEGMENTFIELD: transaction.SEGMENTFIELD,
        TRANSACTIONCODEFIELD: transaction.TRANSACTIONCODE,
      };
    })
    .filter((item) => item !== null); // Filter out any null values resulting from invalid dates

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      sentclientcode: clientCode,
      sentBankAccount: bankAccount,
      sentArray: sentArray,
    }),
  };

  console.log(
    "client sent",
    clientCode,
    "bank account sent",
    bankAccount,
    "data sent",
    sentArray
  );

  const url = `${process.env.REACT_APP_BASE_URL}/SendImportFileData4`;

  return fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((json) => {
      console.log("Response from server:", json);
      return json;
    })
    .catch((error) => {
      console.error("Error during data transmission:", error);
      return { error: "Request failed", details: error };
    });
};

export const updateExcelTransactionsbyNumber = (
  clientcode,
  bankaccount,
  dataArray
) => {
  const sentArray = dataArray.map((transaction) => ({
    DATEFIELD: transaction.date,
    DESCRIPTIONFIELD: transaction.description,
    PAYMENTSFIELD: transaction.debit, // Assuming 'debit' is equivalent to 'payments'
    DEPOSITSFIELD: transaction.credit, // Assuming 'credit' is equivalent to 'deposits'
  }));

  var requestoptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json;",
    },
    body: JSON.stringify({
      sentclientcode: clientcode,
      sentBankAccount: bankaccount,
      sentArray: sentArray,
    }),
  };
  console.log(
    "client sent",
    clientcode,
    "bank account sent",
    bankaccount,
    "data sent",
    sentArray
  );
  //  const url = `${process.env.REACT_APP_BASE_URL}/SendExcelFileData`;
  const url = `${process.env.REACT_APP_BASE_URL}/SendImportFileData4`;

  return fetch(url, requestoptions) // Request fish
    .then((response) => {
      ////console.log("client " + myClient);
      if (!response.ok) {
        return {
          companyname: "System did not respond",
          returnaddress: " ",
        };
      }
      return response.json();
    })
    .then((json) => {
      //console.log("banks list", json);
      return {};
    });
};

//console.log("datey is", transaction.LONGDATEFIELD);
//const dateString = transaction.LONGDATEFIELD; // Ensure this is the expected date format

// Create a date object; the input is assumed to be in local time already
//const date = new Date(dateString);
//console.log("Parsed date object", date);

// Check if the date is valid before proceeding
//if (isNaN(date.getTime())) {
//  console.error("Invalid date encountered:", dateString);
//  return null; // Return null or handle this case as appropriate for your use case
//}

// Assuming the server is in UTC and you want EST, which is UTC-5 hours
// Alternatively, for daylight saving time (EDT), you might use UTC-4
// const offset = date.getTimezoneOffset() === 240 ? -4 : -5; // This checks if it's EDT or EST based on the typical offset in minutes (240 minutes for EDT)
// console.log("Offset in hours", offset);
// const estDate = new Date(date.getTime() + offset * 3600 * 1000); // Convert to EST
// console.log("Corrected date object", estDate);

// Format the corrected date as an ISO string
// const formattedDate = estDate.toISOString();
// console.log("Corrected ISO date", formattedDate);
// const dateString = transaction.LONGDATEFIELD; // Ensure this is the expected date format

// const date = new Date(dateString);
// console.log("date is", date);
// // Check if the date is valid before proceeding
// if (isNaN(date.getTime())) {
//   console.error("Invalid date encountered:", dateString);
//   return null; // Return null or handle this case as appropriate for your use case
// }

// const dateInUTC = new Date(
//   Date.UTC(
//     date.getFullYear(),
//     date.getMonth(),
//     date.getDate(),
//     date.getHours(),
//     date.getMinutes(),
//     date.getSeconds()
//   )
// );
// formattedDate = dateInUTC.toISOString();

// console.log("date is", formattedDate); // Debugging line, consider removing in production
