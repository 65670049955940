import React from "react";
import { useAuth } from "../../../contexts/auth";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  Export,
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import jsPDF from "jspdf";

import { mystore4 } from "./segmentData";
import "devextreme-react/text-area";
import "devextreme/data/data_source";
import "./debtSummaryStyles.scss";
import "./debtsummary.css";
import { GenerateDebtExcel } from "./generateDebtExcel";

const renderDescriptionCell = (data) => {
  const { data: rowData } = data;
  let style = {};
  if (
    rowData.TOTAL === 1 &&
    rowData.GROUPTYPESEQUENCE !== 99 &&
    rowData.GROUPTYPESEQUENCE !== 98
  ) {
    style = {
      fontWeight: "bold",
      backgroundColor: "lightgrey",
      borderTop: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else if (rowData.GROUPTYPESEQUENCE === 99) {
    style = {
      borderRight: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else if (rowData.GROUPTYPESEQUENCE === 98) {
    style = {
      fontWeight: "bold",
      color: "white",
      backgroundColor: "black",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else if (rowData.GROUPTYPESEQUENCE === 97) {
    style = {
      fontWeight: "bold",
      color: "white",
      backgroundColor: "black",
      borderTop: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else {
    if (rowData.DESCRIPTION === "") {
      style = {
        borderRight: "1px solid black",
        borderLeft: "1px solid black",
      };
    } else {
      style = {
        borderTop: "1px solid black",
        borderRight: "1px solid black",
        borderLeft: "1px solid black",
      };
    }
  }

  if (rowData.DESCRIPTION === "") {
    return <div style={style}>&nbsp;</div>;
  }

  return <div style={style}>{rowData.DESCRIPTION}</div>;
};

const renderGroupDescriptionCell = (data) => {
  const { data: rowData } = data;
  let style = {};
  if (
    rowData.TOTAL === 1 &&
    rowData.GROUPTYPESEQUENCE !== 99 &&
    rowData.GROUPTYPESEQUENCE !== 98
  ) {
    if (rowData.GROUPCODEDESCRIPTION !== "") {
      style = {
        fontWeight: "bold",
        borderTop: "1px solid black",
        backgroundColor: "lightgrey",
        borderRight: "1px solid black",
      };
    } else {
      style = {
        backgroundColor: "lightgrey",
        borderTop: "1px solid black",
        borderRight: "1px solid black",
      };
      return <div style={style}>&nbsp;</div>;
    }
  } else if (rowData.GROUPTYPESEQUENCE === 99) {
    style = {
      fontWeight: "bold",
      borderTop: "1px solid black",
      backgroundColor: "lightgrey",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
    };
  } else if (rowData.GROUPTYPESEQUENCE === 98) {
    style = {
      fontWeight: "bold",
      color: "white",
      backgroundColor: "black",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderLeft: "1px solid black",
    };
  } else {
    style = { borderRight: "1px solid black" };
  }

  return <div style={style}>{rowData.GROUPCODEDESCRIPTION}</div>;
};

const renderCurrentValueCell = (data) => {
  const { data: rowData } = data;
  let style = {};
  if (
    rowData.TOTAL === 1 &&
    rowData.GROUPTYPESEQUENCE !== 99 &&
    rowData.GROUPTYPESEQUENCE !== 98
  ) {
    style = {
      fontWeight: "bold",
      borderTop: "1px solid black",
      backgroundColor: "lightgrey",
      borderRight: "1px solid black",
    };
  } else if (rowData.GROUPTYPESEQUENCE === 99) {
    style = {
      fontWeight: "bold",
      borderTop: "1px solid black",
      backgroundColor: "lightgrey",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
    };
  } else if (rowData.GROUPTYPESEQUENCE === 98) {
    style = {
      fontWeight: "bold",
      color: "black",
      backgroundColor: "#E6D180",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    return <div style={style}>Current</div>;
  } else if (rowData.GROUPTYPESEQUENCE === 97) {
    style = {
      borderBottom: "1px solid white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    return <div style={style}>&nbsp;</div>;
  } else {
    style = { borderRight: "1px solid black" };
  }

  const isNegative = rowData.CURRENTVALUE < 0;
  const absoluteValue = Math.abs(rowData.CURRENTVALUE);

  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(absoluteValue);

  const displayValue = isNegative ? `(${formattedValue})` : formattedValue;
  return <div style={style}>{displayValue}</div>;
};

const renderPriorValueCell = (data) => {
  const { data: rowData } = data;
  let style = {};
  if (
    rowData.TOTAL === 1 &&
    rowData.GROUPTYPESEQUENCE !== 99 &&
    rowData.GROUPTYPESEQUENCE !== 98
  ) {
    style = {
      fontWeight: "bold",
      borderTop: "1px solid black",
      backgroundColor: "lightgrey",
      borderRight: "1px solid black",
    };
  } else if (rowData.GROUPTYPESEQUENCE === 99) {
    style = {
      fontWeight: "bold",
      borderTop: "1px solid black",
      backgroundColor: "lightgrey",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
    };
  } else if (rowData.GROUPTYPESEQUENCE === 98) {
    style = {
      fontWeight: "bold",
      color: "black",
      backgroundColor: "#E6D180",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderLeft: "1px solid black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    return <div style={style}>Prior</div>;
  } else if (rowData.GROUPTYPESEQUENCE === 97) {
    style = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    return <div style={style}>&nbsp;</div>;
  } else {
    style = { borderRight: "1px solid black" };
  }

  const isNegative = rowData.PRIORVALUE < 0;
  const absoluteValue = Math.abs(rowData.PRIORVALUE);

  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(absoluteValue);

  const displayValue = isNegative ? `(${formattedValue})` : formattedValue;

  return <div style={style}>{displayValue}</div>;
};

const renderChangeValueCell = (data) => {
  const { data: rowData } = data;
  let style = {};
  if (
    rowData.TOTAL === 1 &&
    rowData.GROUPTYPESEQUENCE !== 99 &&
    rowData.GROUPTYPESEQUENCE !== 98
  ) {
    style = {
      fontWeight: "bold",
      borderTop: "1px solid black",
      backgroundColor: "lightgrey",
      borderRight: "1px solid black",
    };
  } else if (rowData.GROUPTYPESEQUENCE === 99) {
    style = {
      fontWeight: "bold",
      borderTop: "1px solid black",
      backgroundColor: "lightgrey",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
    };
  } else if (rowData.GROUPTYPESEQUENCE === 98) {
    style = {
      fontWeight: "bold",
      color: "black",
      backgroundColor: "#E6D180",
      borderTop: "1px solid black",
      borderBottom: "1px solid black",
      borderLeft: "1px solid black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    return <div style={style}>+/-</div>;
  } else if (rowData.GROUPTYPESEQUENCE === 97) {
    style = {
      borderRight: "1px solid black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    return <div style={style}>&nbsp;</div>;
  } else {
    style = { borderRight: "1px solid black" };
  }

  const isNegative = rowData.CHANGEVALUE < 0;
  const absoluteValue = Math.abs(rowData.CHANGEVALUE);

  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(absoluteValue);

  const displayValue = isNegative ? `(${formattedValue})` : formattedValue;

  return <div style={style}>{displayValue}</div>;
};

class DebtSummaryx extends React.Component {
  constructor(props) {
    super(props);
    this.applyFilterTypes = [
      {
        key: "auto",
        name: "Immediately",
      },
      {
        key: "onClick",
        name: "On Button Click",
      },
    ];
    this.state = {
      currentRow: 0,
      filterValue: "90",
      selectedRowKeys: [],
      showFilterRow: true,
      showHeaderFilter: true,
      companyCode: 1,
      assetGroupsCodes: [],
      currentFilter: this.applyFilterTypes[0].key,
      isLoading: false,
      thisWidth: 800,
      showCurrentOnly: this.props.showPrior,
      banksort: 0,
      ShowCreateClientPresentation: this.props.ShowCreateClientPresentation,
    };
  }

  handleMappingUpdated2 = (value) => {
    this.setState({ EditExcelOn: false });
  };

  CreateExcel = () => {
    this.setState({ EditExcelOn: true });
  };

  handleSelectionChanged(e) {
    this.setState({ selectedRowKeys: e.selectedRowKeys });
    if (e.selectedRowKeys.length > 0) {
      this.setState({ currentRow: e.selectedRowKeys[0] });
    }
  }

  handleEditingStart(e) {
    const rowToBeEdited = e.data;
    if (rowToBeEdited.someField === "someValue") {
      e.cancel = true;
    }
  }

  onRowPrepared(e) {}

  onCellPrepared = (e) => {
    e.cellElement.style.padding = "0px";
  };

  generatePDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    const scale =
      doc.internal.pageSize.width /
      document.getElementById("gridContainer").scrollWidth;
    doc.scaleFactor = scale;

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: this.dataGridRef.instance,
      customizeCell: ({ gridCell, pdfCell }) => {
        pdfCell.fontSize = 7;
        pdfCell.borderColor = "#FFFFFF";

        if (gridCell.rowType === "data") {
          if (
            gridCell.data.TOTAL === 1 &&
            gridCell.data.GROUPTYPESEQUENCE !== 99 &&
            gridCell.data.GROUPTYPESEQUENCE !== 98
          ) {
            pdfCell.fontStyle = "bold";
            pdfCell.backgroundColor = "#D9D9D9";
            pdfCell.textColor = "#000000";
          } else if (gridCell.data.GROUPTYPESEQUENCE === 99) {
            // Custom styling for GROUPTYPESEQUENCE 99
          } else if (gridCell.data.GROUPTYPESEQUENCE === 98) {
            pdfCell.fontStyle = "bold";
            pdfCell.backgroundColor = "#000000";
            pdfCell.textColor = "#FFFFFF";
          } else if (gridCell.data.GROUPTYPESEQUENCE === 97) {
            pdfCell.fontStyle = "bold";
            pdfCell.backgroundColor = "#000000";
            pdfCell.textColor = "#FFFFFF";
          } else if (gridCell.data.DESCRIPTION === "") {
            pdfCell.text = ""; // Render blank line
          } else {
            pdfCell.borderColor = null; // Remove borders for all cells
          }
          pdfCell.borderColor = "#FFFFFF";
        }
      },
    }).then(() => {
      doc.save("debt_summary.pdf");
    });
  };

  render() {
    return (
      <>
        <style>
          {`
          .dx-datagrid-rowsview .dx-row {
            border: none !important;
          }

          .dx-datagrid-headers .dx-header-row {
            border: none !important;
          }

          .dx-datagrid-content .dx-cell {
            border: none !important;
          }
        `}
        </style>
        <div className="root">
          <h1>Debt Summary</h1>
        </div>
        <p></p>
        {this.state.isLoading && (
          <div className="spinner-container">
            {this.state.isLoading && (
              <>
                <p>Building the Excel File please wait &nbsp;&nbsp;</p>
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  className="large-spinner"
                />
              </>
            )}
          </div>
        )}

        {this.state.EditExcelOn !== true && (
          <>
            {this.state.ShowCreateClientPresentation !== true && (
              <>
                <Button
                  text="Create Excel"
                  onClick={this.CreateExcel}
                  style={{
                    width: "200px",
                    height: "30px",
                    marginTop: "2px",
                    marginBottom: "10px",
                    marginLeft: "15px",
                  }}
                ></Button>
                <Button
                  text="Generate PDF"
                  onClick={this.generatePDF}
                  style={{
                    width: "200px",
                    height: "30px",
                    marginTop: "2px",
                    marginBottom: "10px",
                    marginLeft: "15px",
                  }}
                ></Button>
              </>
            )}
            {/* <div className="content-block2 dx-card responsive-paddings"> */}
            <div
              className="custom-debtsummary-container4"
              style={{ height: "850px" }}
            >
              {/* <div className="content-block3 dx-card responsive-paddings"> */}
              <div className="custom-container" style={{ height: "850px" }}>
                <div id="gridContainer">
                  <DataGrid
                    dataSource={mystore4(this.props.clientCode)}
                    onRowPrepared={this.onRowPrepared}
                    onCellPrepared={this.onCellPrepared}
                    scrolling={{ mode: "virtual" }}
                    remoteOperations={false}
                    onSelectionChanged={this.handleSelectionChanged.bind(this)}
                    onEditingStart={this.handleEditingStart}
                    // width={this.state.thisWidth}
                    width={"100%"}
                    height={"100%"}
                    showBorders={false}
                    showRowLines={false}
                    ref={(ref) => (this.dataGridRef = ref)}
                  >
                    {/* <Export enabled={true} /> */}
                    <Paging enabled={false} />
                    <Column
                      dataField="UNIQUEID"
                      caption="Unique ID"
                      visible={false}
                    />
                    <Column
                      dataField="CLIENTCODE"
                      caption="Client"
                      visible={false}
                    />
                    <Column
                      dataField="TOTALSEQ"
                      caption="Seq"
                      visible={false}
                    />
                    <Column
                      dataField="BANKCODE"
                      caption="Bank"
                      visible={false}
                    />
                    <Column
                      dataField="BANKACCOUNTNUMBER"
                      caption="Account"
                      visible={false}
                    />
                    <Column
                      dataField="DESCRIPTION"
                      width={250}
                      cellRender={renderDescriptionCell}
                      caption={""}
                      // caption={`Debt Summary for ${this.props.clientCode}`}
                    />
                    <Column
                      dataField="SEGMENTNUMBER"
                      caption=""
                      format={"###"}
                      alignment="right"
                      width={120}
                      visible={false}
                    />
                    <Column
                      dataField="GROUPCODE"
                      caption="Group"
                      visible={false}
                    />
                    <Column
                      dataField="GROUPCODEDESCRIPTION"
                      cellRender={renderGroupDescriptionCell}
                      caption=""
                      width={200}
                    />
                    <Column
                      dataField="CURRENTVALUE"
                      caption=""
                      format={"$###,###,###"}
                      alignment="right"
                      cellRender={renderCurrentValueCell}
                      width={100}
                    />
                    <Column
                      dataField="PRIORVALUE"
                      caption=""
                      format={"$###,###,###"}
                      alignment="right"
                      visible={this.state.showCurrentOnly}
                      cellRender={renderPriorValueCell}
                      width={100}
                    />
                    <Column
                      dataField="CHANGEVALUE"
                      caption=""
                      format={"$###,###,###"}
                      alignment="right"
                      visible={this.state.showCurrentOnly}
                      cellRender={renderChangeValueCell}
                      width={100}
                    />
                    <Column
                      dataField="GROUPSEQUENCE"
                      caption=""
                      visible={false}
                    />
                    <Column dataField="TOTAL" caption="Total" visible={false} />
                    <Column
                      dataField="BANKSEQUENCE"
                      caption="Bank Seq"
                      visible={false}
                    />
                    <Column
                      dataField="GROUPTYPESEQUENCE"
                      caption="Group Type Seq"
                      visible={false}
                    />
                    <Column
                      dataField="COMPOUND"
                      caption="Compound"
                      visible={false}
                    />
                  </DataGrid>
                </div>
              </div>
            </div>
          </>
        )}
        {this.state.EditExcelOn === true && (
          <div>
            <GenerateDebtExcel
              clientCode={this.props.clientCode}
              onMappingUpdated2={this.handleMappingUpdated2}
            />
          </div>
        )}
      </>
    );
  }
}

export default function DebtSummary(props) {
  const { user } = useAuth();
  return (
    <DebtSummaryx
      clientCode={user.thisClientcode}
      thisWidth={props.thisWidth}
      showPrior={props.showPrior}
      ShowCreateClientPresentation={props.ShowCreateClientPresentation}
    />
  );
}
