import React, { useState, useEffect } from "react";
import SelectBox from "devextreme-react/select-box";
import { getClients } from "../clientManagementData";
import { Button } from "devextreme-react";
import { copycustomerStructure } from "./cfData";

const CFCopyStructure = (props) => {
  console.log(props);
  const [thisCustomer, setThisCustomer] = useState(props.clientCode); // selected customer
  const [customerList, setCustomerList] = useState([]); // array of customers
  const [currentClientCode, setCurrentClientCode] = useState(""); // selected customer code

  useEffect(() => {
    getClients() // call the function to fetch data
      .then((data) => {
        setCustomerList(data.data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the transaction group data:",
          error
        );
      });
  }, []);

  const setCopyFrom = async (e) => {
    if (e.value === null || e.value === undefined || e.value === "") return;
    setCurrentClientCode(e.value);
  };

  const handleCopyStructure = () => {
    copycustomerStructure(thisCustomer, currentClientCode); // Assuming thisCustomer is the selected customer code
    console.log("Copy Structure Clicked");
    props.onClose(); // Call the onClose function passed as a prop to close the form
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
        <p>Client to copy from &nbsp;&nbsp;</p>
        <SelectBox
          className="white-text-selectbox"
          style={{
            width: "200px",
            height: "40px",
            marginRight: "10px",
            marginTop: "10px",
          }}
          items={customerList}
          valueExpr="label" // Assuming 'code' is the property you want to use as the value
          displayExpr="label" // Assuming 'label' is the property you want to display
          value={currentClientCode}
          searchEnabled={true}
          onValueChanged={setCopyFrom}
        />
        <Button
          text="Copy Structure"
          onClick={handleCopyStructure}
          style={{
            width: "200px",
            height: "30px",
            marginTop: "12px", // Adjusted marginTop
            marginBottom: "10px",
          }}
        />
      </div>
    </>
  );
};

export default CFCopyStructure;
