import React, { useEffect, useState } from "react";
import { fetchThisClientData } from "./segmentData";
import beach from "./beach2.jpg"; // Adjust the path accordingly

const PresentationPage = (props) => {
  const [ClientCode, setClientCode] = useState(props.clientCode);

  const [companyValues, setCompanyValues] = useState({
    CLIENTCODE: "",
    NAME: "",
    ADDRESSLINEONE: "",
    ADDRESSLINETWO: "",
    ADDRESSLINETHREE: "",
    ADDRESSLINEFOUR: "",
    COUNTRY: "",
    POSTALZIP: "",
    UNIQUEID: "",
    STARTDATE: "",
    ENDDATE: "",
    COPPERID: "",
    PRESENTATIONDATE: "",
    PRESENTATIONDATE2: "",
    PRESENTATIONNAME: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const result = await fetchThisClientData(ClientCode);
        setCompanyValues(result);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    })();
  }, [ClientCode]);

  return (
    <>
      <style>
        {`
          body, html, #root {
            height: 100%;
            margin: 0;
            display: flex;
            flex-direction: column;
          }

          .container {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: space-between;
            position: relative;
          }

          .header {
            background-color: #333; /* Dark background */
            color: white;
            padding: 20px;
            display: flex;
            align-items: center;
          }

          .header img {
            margin-right: 20px;
          }

          .header-text {
            font-size: 24px;
            font-weight: bold;
          }

          .main-content {
            padding: 20px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }

          .main-content h1 {
            font-size: 56px;
            color: grey;
            text-align: left;
            font-weight: bold;
            margin: 0;
            margin-top: 100px;
          }

          .main-content h2 {
            font-size: 80px !important; /* Make sure this is the final size */
            color: black;
            text-align: left;
            font-weight: bold;
            margin: 0;
            margin-top: 100px;
          }

          .footer2 {
          padding-left: 1600px;
            text-align: left;
            background-color: #d4af37; /* Gold background */
            margin-top: 280px; /* Ensure space above copyright */
            font-size: 20px;
            font-weight: bold;
          }


        `}
      </style>

      <div className="container">
        <div className="header">
          <img src={beach} alt="Logo" height={50} />
        </div>

        <div className="main-content">
          <h1>Progress Report</h1>
          <h2>{companyValues.PRESENTATIONNAME}</h2>
        </div>

        <div className="footer2">
          <p>{companyValues.PRESENTATIONDATE2}</p>
        </div>
      </div>
    </>
  );
};

export default PresentationPage;
