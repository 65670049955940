import React, { useState, useEffect } from "react";
import "./profile.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Button } from "devextreme-react/button";
import {
  fetchThisClientData,
  GenerateCarryingChargesData,
  //GenerateExcelFileForSend,
} from "../clientManagementData";
//import { fetchcompany } from "../../api/MyOwnServices";
import { fetchcompany } from "../../../api/MyOwnServices";
import { set } from "date-fns";

export const GenerateCarryingCharges = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [myClientCode, setClientCode] = useState(props.clientCode);
  const MySwal = withReactContent(Swal);
  const [startPeriod, setStartPeriod] = useState("");
  const [endPeriod, setEndPeriod] = useState("");
  const [filename, setFilename] = useState(""); // State to store the generated filename
  const [ServerUsername, setServerUsername] = useState("");
  const [ServerPassword, setServerPassword] = useState("");
  const [ServerName, setServerName] = useState("");
  const [ServerDatabase, setServerDatabase] = useState("");
  const companynumbersent = "0001";

  useEffect(() => {
    const fetchCompanyData = async () => {
      const result = await fetchcompany(companynumbersent);
      setServerUsername(result.ServerUsername);
      setServerPassword(result.ServerPassword);
      setServerName(result.ServerName);
      setServerDatabase(result.ServerDatabase);
    };

    fetchCompanyData();
  }, []);

  const ProcessCarryingCharges = async (e) => {
    try {
      setIsLoading(true);
      const result = await GenerateCarryingChargesData(
        myClientCode,
        startPeriod,
        endPeriod
      );
      setFilename(result.filename);
      console.log("File created - ", filename, " now send it");
      //const response = await GenerateExcelFileForSend(myClientCode);
      // console.log("File received");

      // if (response && response.filename) {
      //   setFilename(response.filename); // Save the filename to state
      // }

      MySwal.fire({
        icon: "success",
        title: "Completed",
        text: "Carrying charges have been successfully generated.",
      });
    } catch (error) {
      console.error("Error processing interest:", error);
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "There was an issue processing the interest.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("client code value", myClientCode);
    fetchThisClientData(myClientCode).then((data) => {
      console.log("client data", data);
      setStartPeriod(data.startinterestperiod);
      setEndPeriod(data.endinterestperiod);
    });
  }, [myClientCode]);

  const downloadFile = (filename) => {
    const url = `${process.env.REACT_APP_BASE_URL_AXIOS}/download/${filename}`;
    //const url = `http://localhost:4000/download/${filename}`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setFilename(""); // Clear the filename after download
  };

  return (
    <>
      <div className="red-color responsive-paddingsx">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <Button
            text=" Carrying Charges"
            onClick={ProcessCarryingCharges}
            style={{
              width: "250px",
              height: "30px",
              marginTop: "30px",
            }}
          ></Button>
        </div>
      </div>
      {filename && (
        <div className="red-color responsive-paddingsx">
          <h3>File Download is ready</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "50%",
            }}
          >
            <Button
              text="Download Carrying Charges Excel File"
              onClick={() => downloadFile(filename)}
              style={{ width: "350px", height: "30px", marginTop: "30px" }}
            ></Button>
          </div>
        </div>
      )}

      {isLoading && (
        <div className="spinner-container" style={{ marginTop: "50px" }}>
          <p style={{ marginBottom: "10px" }}>
            Processing please wait &nbsp;&nbsp;
          </p>
          <FontAwesomeIcon icon={faSpinner} spin className="large-spinner" />
        </div>
      )}
    </>
  );
};
