import CustomStore from "devextreme/data/custom_store";
function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}
export const mystore = (myClient) =>
  new CustomStore({
    key: "UNIQUEID",
    load: (loadOptions) => {
      let params = "?";
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });

      //mycompany = 1;

      params = params.slice(0, -1);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          sentclientcode: myClient,
          Parameters: params,
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/returnunpostedbankdataonly`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          //console.log("client " + myClient);
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          //console.log(json);
          return {
            data: json.user_response.bankq,
            totalCount: json.user_response.totalCount,
            key: json.user_response.keyname,
          };
        });
    },
    insert: (values) => {
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          ThisFunction: "insert",
          keyvaluepair: values,
          sentclientcode: myClient,
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/updateunpostedbankdataonly`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
    remove: (key) => {
      //console.log(key);
      //console.log(values);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          sentclientcode: key,
          ThisFunction: "delete",
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/updateunpostedbankdataonly`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
    update: (key, values) => {
      //console.log(key);
      //console.log(values);
      var requestoptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json;",
        },
        body: JSON.stringify({
          ThisFunction: "change",
          sentclientcode: key,
          keyvaluepair: values,
        }),
      };
      const url = `${process.env.REACT_APP_BASE_URL}/updateunpostedbankdataonly`;
      return fetch(url, requestoptions) // Request fish
        .then((response) => {
          if (!response.ok) {
            return {
              companyname: "System did not respond",
              returnaddress: " ",
            };
          }
          return response.json();
        })
        .then((json) => {
          return {};
        });
    },
  });
//
//////////////////////////////////////////////////////////////////////////////
//

// const fetchData = (bankID, params, rangeValue) => {
//   var requestoptions = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "application/json;",
//     },
//     body: JSON.stringify({
//       sentbankid: bankID,
//       Parameters: params,
//       daterange: rangeValue,
//     }),
//   };
//   const url = `${process.env.REACT_APP_BASE_URL}/returnbanktransactionsOnly`;

//   return fetch(url, requestoptions).then((response) => {
//     if (!response.ok) {
//       throw new Error("System did not respond");
//     }
//     return response.json();
//   });
// };
// export const mystore2 = (bankID, rangeValue) =>
//   new CustomStore({
//     key: "UNIQUEID",
//     load: (loadOptions) => {
//       let params = "?";
//       [
//         "skip",
//         "take",
//         "requireTotalCount",
//         "requireGroupCount",
//         "sort",
//         "filter",
//         "totalSummary",
//         "group",
//         "groupSummary",
//       ].forEach((i) => {
//         if (i in loadOptions && isNotEmpty(loadOptions[i])) {
//           params += `${i}=${JSON.stringify(loadOptions[i])}&`;
//         }
//       });
//       //myemployee = "b@b.com";
//       //mycompany = 1;
//       //myemployee = "b@b.com";
//       //console.log("bank", bankID, "range", rangeValue);
//       //console.log(rangeValue);
//       params = params.slice(0, -1);
//       var requestoptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json;",
//         },
//         body: JSON.stringify({
//           sentbankid: bankID,
//           Parameters: params,
//           daterange: rangeValue,
//         }),
//       };
//       const url = `${process.env.REACT_APP_BASE_URL}/returnbanktransactionsOnly`;
//       return new Promise((resolve, reject) => {
//         fetch(url, requestoptions)
//           .then((response) => {
//             if (!response.ok) {
//               reject(new Error("System did not respond"));
//             }
//             return response.json();
//           })
//           .then((json) => {
//             console.log(
//               "bank: ",
//               bankID,
//               "data",
//               json.user_response.bankq,
//               "total count",
//               json.user_response.totalCount,
//               "key",
//               json.user_response.keyname
//             );
//             resolve(json.user_response.bankq);
//           })
//           .catch((error) => {
//             reject(error);
//           });
//       });
//     },
//     totalCount: (loadOptions) => {
//       let params = "?";
//       [
//         "skip",
//         "take",
//         "requireTotalCount",
//         "requireGroupCount",
//         "sort",
//         "filter",
//         "totalSummary",
//         "group",
//         "groupSummary",
//       ].forEach((i) => {
//         if (i in loadOptions && isNotEmpty(loadOptions[i])) {
//           params += `${i}=${JSON.stringify(loadOptions[i])}&`;
//         }
//       });
//       return new Promise((resolve, reject) => {
//         fetchData(bankID, params, rangeValue)
//           .then((json) => {
//             resolve(json.user_response.totalCount);
//           })
//           .catch((error) => {
//             reject(error);
//           });
//       });
//     },

//     insert: (values) => {
//       //console.log(values, bankID);
//       var requestoptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json;",
//         },
//         body: JSON.stringify({
//           ThisFunction: "insert",
//           keyvaluepair: values,
//           sentbankID: bankID,
//         }),
//       };
//       const url = `${process.env.REACT_APP_BASE_URL}/UpdateServicelevels`;
//       return fetch(url, requestoptions) // Request fish
//         .then((response) => {
//           if (!response.ok) {
//             return {
//               companyname: "System did not respond",
//               returnaddress: " ",
//             };
//           }
//           return response.json();
//         })
//         .then((json) => {
//           return {};
//         });
//     },
//     remove: (key) => {
//       //console.log(key);
//       //console.log(values);
//       var requestoptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json;",
//         },
//         body: JSON.stringify({
//           SentCompany: key,
//           ThisFunction: "delete",
//         }),
//       };
//       const url = `${process.env.REACT_APP_BASE_URL}/UpdateServicelevels`;
//       return fetch(url, requestoptions) // Request fish
//         .then((response) => {
//           if (!response.ok) {
//             return {
//               companyname: "System did not respond",
//               returnaddress: " ",
//             };
//           }
//           return response.json();
//         })
//         .then((json) => {
//           return {};
//         });
//     },
//     update: (key, values) => {
//       //console.log(key);
//       //console.log(values);
//       var requestoptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json;",
//         },
//         body: JSON.stringify({
//           ThisFunction: "change",
//           SentCompany: key,
//           keyvaluepair: values,
//         }),
//       };
//       const url = `${process.env.REACT_APP_BASE_URL}/UpdateServicelevels`;
//       return fetch(url, requestoptions) // Request fish
//         .then((response) => {
//           if (!response.ok) {
//             return {
//               companyname: "System did not respond",
//               returnaddress: " ",
//             };
//           }
//           return response.json();
//         })
//         .then((json) => {
//           return {};
//         });
//     },
//   });

// //////////////////////////////////////////////////////
// ////////////// old code
// /////////////////////////////////////////////////////
// export const oldmystore2 = (bankID, rangeValue) =>
//   new CustomStore({
//     key: "UNIQUEID",
//     load: (loadOptions) => {
//       let params = "?";
//       [
//         "skip",
//         "take",
//         "requireTotalCount",
//         "requireGroupCount",
//         "sort",
//         "filter",
//         "totalSummary",
//         "group",
//         "groupSummary",
//       ].forEach((i) => {
//         if (i in loadOptions && isNotEmpty(loadOptions[i])) {
//           params += `${i}=${JSON.stringify(loadOptions[i])}&`;
//         }
//       });
//       //myemployee = "b@b.com";
//       //mycompany = 1;
//       //myemployee = "b@b.com";
//       //console.log("bank", bankID, "range", rangeValue);
//       //console.log(rangeValue);
//       params = params.slice(0, -1);
//       var requestoptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json;",
//         },
//         body: JSON.stringify({
//           sentbankid: bankID,
//           Parameters: params,
//           daterange: rangeValue,
//         }),
//       };
//       const url = `${process.env.REACT_APP_BASE_URL}/returnbanktransactionsOnly`;
//       return fetch(url, requestoptions) // Request fish
//         .then((response) => {
//           if (!response.ok) {
//             return {
//               companyname: "System did not respond",
//               returnaddress: " ",
//             };
//           }
//           return response.json();
//         })
//         .then((json) => {
//           console.log("bank: ", bankID, "data", json.user_response.bankq);
//           return {
//             data: json.user_response.bankq,
//             totalCount: json.user_response.totalCount,
//             key: json.user_response.keyname,
//           };
//         });
//     },
//     insert: (values) => {
//       //console.log(values, bankID);
//       var requestoptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json;",
//         },
//         body: JSON.stringify({
//           ThisFunction: "insert",
//           keyvaluepair: values,
//           sentbankID: bankID,
//         }),
//       };
//       const url = `${process.env.REACT_APP_BASE_URL}/UpdateServicelevels`;
//       return fetch(url, requestoptions) // Request fish
//         .then((response) => {
//           if (!response.ok) {
//             return {
//               companyname: "System did not respond",
//               returnaddress: " ",
//             };
//           }
//           return response.json();
//         })
//         .then((json) => {
//           return {};
//         });
//     },
//     remove: (key) => {
//       //console.log(key);
//       //console.log(values);
//       var requestoptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json;",
//         },
//         body: JSON.stringify({
//           SentCompany: key,
//           ThisFunction: "delete",
//         }),
//       };
//       const url = `${process.env.REACT_APP_BASE_URL}/UpdateServicelevels`;
//       return fetch(url, requestoptions) // Request fish
//         .then((response) => {
//           if (!response.ok) {
//             return {
//               companyname: "System did not respond",
//               returnaddress: " ",
//             };
//           }
//           return response.json();
//         })
//         .then((json) => {
//           return {};
//         });
//     },
//     update: (key, values) => {
//       //console.log(key);
//       //console.log(values);
//       var requestoptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json;",
//         },
//         body: JSON.stringify({
//           ThisFunction: "change",
//           SentCompany: key,
//           keyvaluepair: values,
//         }),
//       };
//       const url = `${process.env.REACT_APP_BASE_URL}/UpdateServicelevels`;
//       return fetch(url, requestoptions) // Request fish
//         .then((response) => {
//           if (!response.ok) {
//             return {
//               companyname: "System did not respond",
//               returnaddress: " ",
//             };
//           }
//           return response.json();
//         })
//         .then((json) => {
//           return {};
//         });
//     },
//   });
